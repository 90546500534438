import Box from "@mui/material/Box";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  DealershipFormPage_Initial_DataQuery,
  PaymentMethods,
  useDealershipFormPage_DealershipQuery,
  useDealershipFormPage_Initial_DataQuery,
  useDealershipFormPage_UpsertDealershipMutation,
} from "../../../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { toast } from "react-hot-toast";
import { useTenant } from "../../../context/TenantContext";
import { BackToolbar } from "../../../components/BackToolbar";
import { DatePicker } from "@mui/x-date-pickers";
import orderBy from "lodash.orderby";
import { NumberField } from "../../../../components/NumberField";

type DealershipFormData = {
  systemId?: string;
  corporateName?: string;
  fantasyName?: string;
  contractorName?: string;
  contractorEmail?: string;
  contractorDateOfBirth?: Date | null;
  cnpj?: string;
  ie?: string;
  im?: string;
  website?: string;
  erpCode?: string;
  phone?: string;
  branchesQty?: string;
  ravmenu?: boolean;
  scalar?: boolean;
  ravpesquisa?: boolean;
  equipmentType?: string;
  equipmentQty?: string;
  address?: string;
  neighbourhood?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  invoicingPhone?: string;
  invoicingEmail?: string;
  invoiceXMLEmail?: string;
  corporateName1?: string;
  phone1?: string;
  corporateName2?: string;
  phone2?: string;
  corporateName3?: string;
  phone3?: string;
  sellerName?: string;
  paymentPeriod?: string;
  notes?: string;
  maxparts?: boolean;
  vwcaminhoes?: boolean;
  brandIds: string[];

  pix?: string;
  bankNumber?: string;
  bankAgency?: string;
  bankAccount?: string;
  PaymentMethod?: PaymentMethods;
};

export function DealershipFormPage() {
  const { id } = useParams();
  const { data, loading } = useDealershipFormPage_DealershipQuery({
    variables: { uuid: id! },
    skip: !id,
  });
  const { data: initialData, loading: initialDataLoading } =
    useDealershipFormPage_Initial_DataQuery();
  const [upsertDealership, { loading: upsertLoading }] =
    useDealershipFormPage_UpsertDealershipMutation();
  const { groupId, refetch } = useTenant();
  const navigate = useNavigate();
  const onSubmit = async (data: DealershipFormData) => {
    await toast.promise(
      upsertDealership({
        variables: {
          uuid: id,
          ...data,
          equipmentQty: safelyParseInt(data.equipmentQty),
          branchesQty: safelyParseInt(data.branchesQty),
          paymentPeriod: safelyParseInt(data.paymentPeriod),
          groupId: groupId!,
        },
      }),
      {
        loading: "Cadastrando",
        success: "Sucesso!",
        error: "Erro, tente novamente.",
      }
    );
    refetch();
    navigate("/ravpro/dealerships");
  };
  return (
    <Box>
      <BackToolbar />
      {!loading && !initialDataLoading ? (
        <DealershipForm
          {...data?.dealership}
          brandIds={data?.dealership.Brands.map((i) => i.uuid) ?? []}
          systemId={data?.dealership?.System?.uuid}
          systems={initialData?.systems ?? []}
          brands={initialData?.brands ?? []}
          equipmentQty={data?.dealership.equipmentQty?.toString() ?? "0"}
          branchesQty={data?.dealership.branchesQty?.toString() ?? "0"}
          paymentPeriod={data?.dealership.paymentPeriod?.toString() ?? "0"}
          onSubmit={onSubmit}
          loading={upsertLoading}
        />
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;
  margin: 16px auto;
`;

type DealershipFormProps = DealershipFormData & {
  onSubmit: (data: DealershipFormData) => void;
  loading: boolean;
  systems: DealershipFormPage_Initial_DataQuery["systems"];
  brands: DealershipFormPage_Initial_DataQuery["brands"];
};

const DealershipForm: React.FC<DealershipFormProps> = ({
  onSubmit,
  loading,
  systems,
  brands,
  systemId,
  corporateName,
  fantasyName,
  contractorName,
  contractorEmail,
  contractorDateOfBirth,
  cnpj,
  ie,
  im,
  website,
  erpCode,
  phone,
  branchesQty,
  ravmenu,
  scalar,
  ravpesquisa,
  equipmentType,
  equipmentQty,
  address,
  neighbourhood,
  city,
  state,
  zipcode,
  invoicingPhone,
  invoicingEmail,
  invoiceXMLEmail,
  corporateName1,
  phone1,
  corporateName2,
  phone2,
  corporateName3,
  phone3,
  sellerName,
  paymentPeriod,
  notes,
  maxparts,
  vwcaminhoes,
  brandIds,

  pix,
  bankNumber,
  bankAgency,
  bankAccount,
  PaymentMethod,
}) => {
  const defaultValues = {
    systemId: systemId ?? "",
    corporateName: corporateName ?? "",
    fantasyName: fantasyName ?? "",
    contractorName: contractorName ?? "",
    contractorEmail: contractorEmail ?? "",
    contractorDateOfBirth: contractorDateOfBirth ?? null,
    cnpj: cnpj ?? "",
    ie: ie ?? "",
    im: im ?? "",
    website: website ?? "",
    erpCode: erpCode ?? "",
    phone: phone ?? "",
    branchesQty: branchesQty ?? "",
    ravmenu: ravmenu ?? false,
    scalar: scalar ?? false,
    ravpesquisa: ravpesquisa ?? false,
    equipmentType: equipmentType ?? "",
    equipmentQty: equipmentQty ?? "",
    address: address ?? "",
    neighbourhood: neighbourhood ?? "",
    city: city ?? "",
    state: state ?? "",
    zipcode: zipcode ?? "",
    invoicingPhone: invoicingPhone ?? "",
    invoicingEmail: invoicingEmail ?? "",
    invoiceXMLEmail: invoiceXMLEmail ?? "",
    corporateName1: corporateName1 ?? "",
    phone1: phone1 ?? "",
    corporateName2: corporateName2 ?? "",
    phone2: phone2 ?? "",
    corporateName3: corporateName3 ?? "",
    phone3: phone3 ?? "",
    sellerName: sellerName ?? "",
    paymentPeriod: paymentPeriod ?? "",
    notes: notes ?? "",
    maxparts: maxparts ?? false,
    vwcaminhoes: vwcaminhoes ?? false,
    brandIds: brandIds ?? [],
    pix: pix ?? "",
    bankNumber: bankNumber ?? "",
    bankAgency: bankAgency ?? "",
    bankAccount: bankAccount ?? "",
    PaymentMethod: PaymentMethod ?? PaymentMethods.Card,
  };
  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues,
  });

  const paymentMethod = watch("PaymentMethod");

  const brandMapper: Record<
    string,
    DealershipFormPage_Initial_DataQuery["brands"][0]
  > = brands.reduce((acc, i) => ({ ...acc, [i.uuid]: i }), {});

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="corporateName"
        control={control}
        defaultValue={defaultValues.corporateName ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Razão social" />
        )}
      />
      <Controller
        name="fantasyName"
        control={control}
        defaultValue={defaultValues.fantasyName ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Nome fantasia" />
        )}
      />
      <Controller
        name="contractorName"
        control={control}
        defaultValue={defaultValues.contractorName ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Nome contratante" />
        )}
      />
      <Controller
        name="contractorEmail"
        control={control}
        defaultValue={defaultValues.contractorEmail ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Email contratante" />
        )}
      />
      <DatePicker
        label="Nascimento contratante"
        value={watch("contractorDateOfBirth")}
        onChange={(date) => setValue("contractorDateOfBirth", date)}
        renderInput={(params) => <TextField {...params} />}
        views={["year", "month", "day"]}
      />

      <Controller
        name="cnpj"
        control={control}
        defaultValue={defaultValues.cnpj ?? ""}
        render={({ field }) => <TextField inputProps={field} label="CNPJ" />}
      />
      <Controller
        name="ie"
        control={control}
        defaultValue={defaultValues.ie ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Inscrição estadual" />
        )}
      />
      <Controller
        name="im"
        control={control}
        defaultValue={defaultValues.im ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Inscrição municipal" />
        )}
      />
      <Controller
        name="website"
        control={control}
        defaultValue={defaultValues.website ?? ""}
        render={({ field }) => <TextField inputProps={field} label="Site" />}
      />
      <Controller
        name="phone"
        control={control}
        defaultValue={defaultValues.phone ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Telefone da loja" />
        )}
      />
      <Autocomplete
        multiple
        options={orderBy(brands, "name")}
        renderInput={(params) => <TextField {...params} label="Bandeira" />}
        onChange={(_e, value) =>
          setValue(
            "brandIds",
            value.map((i) => i.uuid)
          )
        }
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        getOptionLabel={(i) => i.name}
        defaultValue={defaultValues.brandIds.map((i) => brandMapper[i])}
        sx={{ marginBottom: 2, width: "100%" }}
      />
      <Controller
        name="branchesQty"
        control={control}
        defaultValue={defaultValues.branchesQty ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <NumberField
            onChange={onChange}
            name={name}
            value={value}
            label="Quantidade filiais"
          />
        )}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="ravmenu"
            defaultChecked={defaultValues.ravmenu}
            onChange={(e) => {
              setValue("ravmenu", e.target.checked);
            }}
          />
        }
        label="RavMenu"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="scalar"
            defaultChecked={defaultValues.scalar}
            onChange={(e) => {
              setValue("scalar", e.target.checked);
            }}
          />
        }
        label="Scalar"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="ravpesquisa"
            defaultChecked={defaultValues.ravpesquisa}
            onChange={(e) => {
              setValue("ravpesquisa", e.target.checked);
            }}
          />
        }
        label="RavPesquisa"
      />
      <FormControl>
        <InputLabel>Sistema</InputLabel>
        <Controller
          name="systemId"
          defaultValue={defaultValues.systemId ?? ""}
          render={({ field }) => (
            <Select {...field} label="Sistema">
              <MenuItem value="">
                <em>Nenhum</em>
              </MenuItem>
              {systems.map((i) => (
                <MenuItem key={i.uuid} value={i.uuid}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
          )}
          control={control}
        />
      </FormControl>
      <Controller
        name="erpCode"
        control={control}
        defaultValue={defaultValues.erpCode ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Código ERP" />
        )}
      />
      <Controller
        name="equipmentType"
        control={control}
        defaultValue={defaultValues.equipmentType ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Tipo equipamento" />
        )}
      />
      <Controller
        name="equipmentQty"
        control={control}
        defaultValue={defaultValues.equipmentQty ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <NumberField
            onChange={onChange}
            name={name}
            value={value}
            label="Quantidade equipamento"
          />
        )}
      />
      <Controller
        name="address"
        control={control}
        defaultValue={defaultValues.address ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Endereço" />
        )}
      />
      <Controller
        name="neighbourhood"
        control={control}
        defaultValue={defaultValues.neighbourhood ?? ""}
        render={({ field }) => <TextField inputProps={field} label="Bairro" />}
      />
      <Controller
        name="city"
        control={control}
        defaultValue={defaultValues.city ?? ""}
        render={({ field }) => <TextField inputProps={field} label="Cidade" />}
      />
      <Controller
        name="state"
        control={control}
        defaultValue={defaultValues.state ?? ""}
        render={({ field }) => <TextField inputProps={field} label="Estado" />}
      />
      <Controller
        name="zipcode"
        control={control}
        defaultValue={defaultValues.zipcode ?? ""}
        render={({ field }) => <TextField inputProps={field} label="CEP" />}
      />
      <Controller
        name="invoicingPhone"
        control={control}
        defaultValue={defaultValues.invoicingPhone ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Telefone faturamento" />
        )}
      />
      <Controller
        name="invoicingEmail"
        control={control}
        defaultValue={defaultValues.invoicingEmail ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Email faturamento" />
        )}
      />
      <Controller
        name="invoiceXMLEmail"
        control={control}
        defaultValue={defaultValues.invoiceXMLEmail ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Email nota fiscal XML" />
        )}
      />
      <Controller
        name="corporateName1"
        control={control}
        defaultValue={defaultValues.corporateName1 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Razão social REFERÊNCIA 1" />
        )}
      />
      <Controller
        name="phone1"
        control={control}
        defaultValue={defaultValues.phone1 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Telefone REFERÊNCIA 1" />
        )}
      />
      <Controller
        name="corporateName2"
        control={control}
        defaultValue={defaultValues.corporateName2 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Razão social REFERÊNCIA 2" />
        )}
      />
      <Controller
        name="phone2"
        control={control}
        defaultValue={defaultValues.phone2 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Telefone REFERÊNCIA 2" />
        )}
      />
      <Controller
        name="corporateName3"
        control={control}
        defaultValue={defaultValues.corporateName3 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Razão social REFERÊNCIA 3" />
        )}
      />
      <Controller
        name="phone3"
        control={control}
        defaultValue={defaultValues.phone3 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Telefone REFERÊNCIA 3" />
        )}
      />
      <Controller
        name="sellerName"
        control={control}
        defaultValue={defaultValues.sellerName ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Vendedor Rav" />
        )}
      />
      <Controller
        name="paymentPeriod"
        control={control}
        defaultValue={defaultValues.paymentPeriod ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <NumberField
            onChange={onChange}
            name={name}
            value={value}
            label="Prazo de pagamentos"
          />
        )}
      />
      <Controller
        name="notes"
        control={control}
        defaultValue={defaultValues.notes ?? ""}
        render={({ field }) => (
          <TextField multiline inputProps={field} label="Anotações" />
        )}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="maxparts"
            defaultChecked={defaultValues.maxparts}
            onChange={(e) => {
              setValue("maxparts", e.target.checked);
            }}
          />
        }
        label="MaxParts"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="vwcaminhoes"
            defaultChecked={defaultValues.vwcaminhoes}
            onChange={(e) => {
              setValue("vwcaminhoes", e.target.checked);
            }}
          />
        }
        label="VW Caminhões"
      />
      <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
        Pagamento premiação
      </Typography>
      <FormControl>
        <InputLabel>Sistema</InputLabel>
        <Controller
          name="PaymentMethod"
          defaultValue={defaultValues.PaymentMethod ?? PaymentMethods.Card}
          render={({ field }) => (
            <Select {...field} label="Método">
              <MenuItem value="">
                <em>Nenhum</em>
              </MenuItem>
              {PaymentMethodData.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
          )}
          control={control}
        />
      </FormControl>
      {PaymentMethods.Pix === paymentMethod ? (
        <Controller
          name="pix"
          control={control}
          defaultValue={defaultValues.pix ?? ""}
          render={({ field }) => <TextField inputProps={field} label="Pix" />}
        />
      ) : null}
      {PaymentMethods.Transfer === paymentMethod ? (
        <>
          <Controller
            name="bankNumber"
            control={control}
            defaultValue={defaultValues.bankNumber ?? ""}
            render={({ field: { onChange, name, value } }) => (
              <NumberField
                onChange={onChange}
                name={name}
                value={value}
                label="Banco"
              />
            )}
          />
          <Controller
            name="bankAgency"
            control={control}
            defaultValue={defaultValues.bankAgency ?? ""}
            render={({ field: { onChange, name, value } }) => (
              <NumberField
                onChange={onChange}
                name={name}
                value={value}
                label="Agência"
              />
            )}
          />
          <Controller
            name="bankAccount"
            control={control}
            defaultValue={defaultValues.bankAccount ?? ""}
            render={({ field }) => (
              <TextField inputProps={field} label="Conta" />
            )}
          />
        </>
      ) : null}

      <Button type="submit" variant="contained" disabled={loading}>
        Cadastrar
      </Button>
    </Form>
  );
};

const safelyParseInt = (x: string | undefined) =>
  x && x.length > 0 ? parseInt(x) : 0;

const PaymentMethodData = [
  {
    id: PaymentMethods.Card,
    name: "Cartão",
  },
  {
    id: PaymentMethods.Pix,
    name: "Pix",
  },
  {
    id: PaymentMethods.Transfer,
    name: "Transferência",
  },
];
