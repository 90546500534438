import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  useGroupFormPage_GroupQuery,
  useGroupFormPage_UpsertGroupMutation,
} from "../../../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { toast } from "react-hot-toast";
import { useTenant } from "../../../context/TenantContext";
import { BackToolbar } from "../../../components/BackToolbar";

type GroupFormData = {
  name?: string;
  integrated?: boolean;
};

export function GroupFormPage() {
  const { id } = useParams();
  const { data, loading } = useGroupFormPage_GroupQuery({
    variables: { uuid: id! },
    skip: !id,
  });
  const { refetch } = useTenant();
  const [upsertGroup, { loading: upsertLoading }] =
    useGroupFormPage_UpsertGroupMutation();

  const navigate = useNavigate();

  const onSubmit = async (data: GroupFormData) => {
    await toast.promise(
      upsertGroup({
        variables: {
          uuid: id,
          name: data.name,
          integrated: data.integrated ?? false,
        },
      }),
      {
        loading: "Cadastrando",
        success: "Sucesso!",
        error: "Erro, tente novamente.",
      }
    );
    refetch();
    navigate("/ravpro/services");
  };
  return (
    <Box>
      <BackToolbar />
      {!loading ? (
        <GroupForm
          name={data?.group.name}
          integrated={data?.group.integrated}
          onSubmit={onSubmit}
          loading={upsertLoading}
        />
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;
  margin: 16px auto;
`;

type GroupFormProps = GroupFormData & {
  onSubmit: (data: GroupFormData) => void;
  loading: boolean;
};

const GroupForm: React.FC<GroupFormProps> = ({
  name,
  integrated,
  onSubmit,
  loading,
}) => {
  const defaultValues = {
    name,
    integrated,
  };
  const { handleSubmit, control, setValue } = useForm({
    defaultValues,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControlLabel
        control={
          <Checkbox
            name="integrated"
            defaultChecked={
              defaultValues.integrated === undefined
                ? true
                : defaultValues.integrated
            }
            onChange={(e) => {
              setValue("integrated", e.target.checked);
            }}
          />
        }
        label="Integrado"
      />
      <Controller
        name="name"
        control={control}
        defaultValue={defaultValues.name ?? ""}
        render={({ field }) => <TextField inputProps={field} label="Nome" />}
      />

      <Button type="submit" variant="contained" disabled={loading}>
        Cadastrar
      </Button>
    </Form>
  );
};
