import scalar from "./scalar";
import linx from "./linx";
import mercosul from "./mercosul";
import vipcar from "./vipcar";
import ravpro from "./ravpro";
import bi from "./bi";
import stgravpro from "./stgravpro";
import toyopar from "./toyopar";
import toyota from "./toyota";
import daf from "./daf";

const groups = {
  scalar,
  linx,
  mercosul,
  vipcar,
  ravpro,
  bi,
  stgravpro,
  toyopar,
  toyota,
  daf,
};

export interface ThemeVars {
  appBarColor: string;
  primaryColor: string;
  tabTitle: string;
  faviconUrl: string;
  loginRightBackgroundColor: string;
  hideLogoWhenMenuUncollapsed: boolean;
  loginLogoHeight?: number;
}

export interface Theme {
  logo: string;
  group: string;
  loginLogo: string;
  theme: ThemeVars;
}

export const getGroupTheme = (): Theme => {
  //eslint-disable-next-line
  const group = /:\/\/([^\/]+)/
    .exec(window.location.href)?.[1]
    .split(".")[0] as keyof typeof groups;

  const groupTheme = groups[group];

  return groupTheme ? groupTheme : scalar;
};
