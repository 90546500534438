import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTenant } from "../context/TenantContext";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

type GroupDropdownProps = {
  disabled?: boolean;
};

export const GroupDropdown: React.FC<GroupDropdownProps> = ({ disabled }) => {
  const { groups, groupId, setGroupId, setDealershipId } = useTenant();
  const { pathname } = useLocation();
  const isEntries = pathname.includes("/ravpro/entries");
  const filteredList = isEntries ? groups.filter((i) => !i.integrated) : groups;
  useEffect(() => {
    if (!filteredList.filter((i) => i.uuid === groupId)?.[0] && isEntries) {
      setGroupId(undefined);
      setDealershipId(undefined);
    }
  }, [pathname]);
  return (
    <Autocomplete
      options={filteredList}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} label="Grupo" />}
      onChange={(_e, value) => setGroupId(value?.uuid)}
      value={filteredList.filter((i) => i.uuid === groupId)?.[0] || null}
      sx={{ marginBottom: 1, width: "100%" }}
      disabled={disabled}
    />
  );
};
