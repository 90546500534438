import styled from "@emotion/styled";
import {
  Box,
  Icon,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Manufacturers,
  useProductTablePage_DeleteProductMutation,
  useProductTablePage_ProductsQuery,
} from "../../../../generated/graphql";
import { TabPanel } from "../../../components/TabPanel";
import { useState } from "react";
import DeleteModal from "../../../../components/DeleteModal";
import { GroupDropdown } from "../../../components/GroupDropdown";
import { DealershipDropdown } from "../../../components/DealershipDropdown";
import { useTenant } from "../../../context/TenantContext";

const Container = styled("div")`
  margin: 32px;
`;

const CToolbar = styled(Toolbar)`
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`;

const Title = styled(Typography)`
  flex: 1;
`;

export const ProductTablePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("manufacturer") as Manufacturers;
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => {
            setSearchParams({ manufacturer: value });
          }}
        >
          <Tab label="Ravpro" value={Manufacturers.Ravpro} />
          <Tab label="Original" value={Manufacturers.Original} />
          <Tab label="Outros" value={Manufacturers.Other} />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} tab={Manufacturers.Ravpro}>
        <ProductTable tab={selectedTab} />
      </TabPanel>
      <TabPanel value={selectedTab} tab={Manufacturers.Original}>
        <ProductTable tab={selectedTab} />
      </TabPanel>
      <TabPanel value={selectedTab} tab={Manufacturers.Other}>
        <ProductTable tab={selectedTab} />
      </TabPanel>
    </>
  );
};

export const ProductTable = ({ tab }: { tab: Manufacturers }) => {
  const { groupId, dealershipId } = useTenant();

  const navigate = useNavigate();
  const { data, refetch } = useProductTablePage_ProductsQuery({
    variables: {
      Manufacturer: tab,
      groupId: groupId!,
      dealershipId: dealershipId ?? undefined,
    },
    skip: !groupId,
  });
  const [searchParams] = useSearchParams();
  const manufacturer = searchParams.get("manufacturer") as Manufacturers;
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [deleteProduct, { loading: deleteLoading }] =
    useProductTablePage_DeleteProductMutation();

  return (
    <Container>
      <GroupDropdown />
      <DealershipDropdown />
      <Paper>
        <CToolbar>
          <Title>Catálogos</Title>
          <IconButton
            onClick={() =>
              navigate(`/ravpro/products/create?manufacturer=${manufacturer}`)
            }
          >
            <Icon>add</Icon>
          </IconButton>
        </CToolbar>
        <Table>
          {data?.products && data?.products.length > 0 ? (
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Código</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {_.orderBy(data?.products, "name").map((row) => (
              <TableRow key={row.uuid} hover>
                <TableCell>{row.ProductCatalog?.name}</TableCell>
                <TableCell>{row.productCode}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() =>
                      navigate(
                        `/ravpro/products/update/${row.uuid}?manufacturer=${manufacturer}`
                      )
                    }
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setDeleteId(row.uuid);
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {data?.products && data.products.length === 0 ? (
              <TableRow>
                <TableCell align="center">Nenhum registro encontrado</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Paper>
      <DeleteModal
        open={!!deleteId}
        onClose={() => setDeleteId(undefined)}
        loading={deleteLoading}
        onConfirm={async () => {
          await deleteProduct({ variables: { uuid: deleteId! } });
          setDeleteId(undefined);
          refetch();
        }}
      />
    </Container>
  );
};
