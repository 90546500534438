import * as React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Outlet, useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import { IconButton } from "./IconButton";
import { Icon } from "./Icon";
import { IconType } from "../assets/icons";
import Collapsible from "react-collapsible";
import categories from "../groups/categories";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { getGroupTheme } from "../groups";
import { UserRole } from "../generated/graphql";

type AppBarProps = {
  open: boolean;
};

type DrawerProps = {
  open: boolean;
};

type ContentProps = {
  open: boolean;
};

const AppBar = styled("div")<AppBarProps>`
  width: ${(props) =>
    props.open ? "calc(100vw - 300px)" : "calc(100vw - 60px)"};
  margin-left: ${(props) => (props.open ? "300px" : "60px")};
  background-color: ${(props) => props.theme.palette.secondary.main};
  height: 60px;
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  @media (max-width: 485px) {
    width: ${(props) => (!props.open ? "100vw" : "calc(100vw - 300px)")};
    margin-left: ${(props) => (!props.open ? "0" : "300px")};
  }
`;

const Drawer = styled("div")<DrawerProps>`
  @media (max-width: 485px) {
    display: ${(props) => (!props.open ? "none" : "block")};
  }
  width: ${(props) => (props.open ? "300px" : "60px")};
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: 1px 0 20px rgb(0 0 0 / 8%);
  z-index: 999;

  ${(props) =>
    !props.open
      ? `
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  & > ul > li {
    float: left;
    position: relative;
    cursor: pointer;
  }
  & ul li span {
    text-decoration: none;
    display: none;
  }

  & ul li:hover {    
    min-width: 350px;
    background-color: #f7fafc;
  }

  & > ul > li > a:hover  {
    min-width: 350px;
    background-color: #dfe0e2;
  }

  & ul ul {
    position: absolute;
    padding: 0;
    display: none;
    top: 100%;
    left: 0;
    margin-left: 60px;
  }
  & ul li:hover > ul {
    background: #f7fafc;
    display: block;
  }
  & ul li:hover >  a span {
    display: block; 
  }
  & > ul > li:hover >  a {
    min-width: 350px;
  }
  & ul ul li > a span {
    display: block;
  }
  & ul li ul li:hover > a span {
    color: ${props.theme.palette.primary.main};
  }
  & ul ul li:hover {
    min-width: 250px;
    background: #f7fafc;  
  }
  & ul ul li {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0px 20px;
    min-width: 250px;
  }
  & ul li {
    display: flex;
    flex-direction: row;
  }
`
      : `
  overflow: auto;
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  & ul li ul li:hover > a span {
    color: ${props.theme.palette.primary.main};
  }
  & ul li ul li a {
    padding: 0 0 0 60px;
  }
`}
`;

type ListItemProps = {
  selected: boolean;
};

const ListItem = styled("li")<ListItemProps>`
  cursor: pointer;
`;

const ListItemText = styled("span")<ListItemProps>`
  width: 100%;
  ${({ selected }) => (selected ? "font-weight: bold;" : "")}
`;

const SubListItem = styled("li")<ListItemProps>`
  cursor: pointer;
  padding: 15px 0;
  &:hover > span a {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
const SubListItemText = styled("span")<ListItemProps>`
  ${({ selected }) => (selected ? "font-weight: bold;" : "")}
`;

const Content = styled("div")<ContentProps>`
  max-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  overflow: auto;
  position: absolute;
  top: 60px;
  right: 0;
  left: ${(props) => (props.open ? "300px" : "60px")};
  z-index: 1;
  @media (max-width: 485px) {
    left: ${(props) => (!props.open ? "0px" : "300px")};
  }
`;

const DrawerButton = styled(IconButton)`
  color: white;
  height: 40px;
  padding-top: 20px;
  padding-bottom: 0;
`;

const FullscreenButton = styled(IconButton)`
  color: white;
  height: 40px;
  padding-top: 20px;
  padding-bottom: 0;
`;

const ProfileButton = styled(Button)`
  padding-right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: none;
  @media (max-width: 485px) {
    & p {
      display: none;
    }
  }
`;

const MenuIcon = styled(Icon)<ListItemProps>`
  z-index: 999;
  ${(props) =>
    props.selected
      ? `border-left: 3px solid ${props.theme.palette.primary.main};`
      : ""}
  ${(props) =>
    props.selected
      ? `color: ${props.theme.palette.primary.main};`
      : "color: #54667a;"}
  padding: 16px 20px;
`;

const ProfileIcon = styled(Icon)`
  margin-right: 12px;
`;

const Spacer = styled("div")`
  flex: 1;
`;
const CollapsedLink = styled(Link)`
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  color: #54667a;
  display: flex;
  flex-direction: row;
  padding: 15px 0;
`;
const UncollapsedLink = styled(Link)`
  max-width: 300px;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  color: #54667a;
  display: flex;
  flex-direction: row;
`;

const CategoryListContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding: 15px 0;
  min-width: 300px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

type MenuChevronIconProps = {
  open: boolean;
};

const MenuChevronIcon = styled(Icon)<MenuChevronIconProps>`
  transform: rotate(0deg) translateX(-5px) translateY(5px);
  overflow: hidden;
  padding: 0 22px;
  font-size: 12px;
  transform-origin: 50% 50%;
  font-weight: bold;
  color: #686868;
  ${(props) => props.open && `transform: rotate(90deg) translateY(7px);`};
`;

const DrawerHeaderContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  margin-bottom: 40px;
  margin-left: 10px;
  margin-top: 10px;
`;

const SectionText = styled("div")`
  color: #a6afbb;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 24px 0;
`;

export default function LoggedInLayout() {
  const [open, setOpen] = React.useState(false);
  const { dashboards, userDetails, signout, userRoles } = useAuth();
  const navigate = useNavigate();
  const { categoryId: selectedCategoryId, dashboardId: selectedDashboardId } =
    useParams();

  const handleSubCategoryClick = (
    categoryId: string,
    subCategoryId: string
  ) => {
    navigate(`/dashboard/${categoryId}/sub-category/${subCategoryId}`);
  };

  const collapsedList = () => (
    <ul>
      {dashboards?.map((i) => (
        <ListItem key={i.Id} selected={selectedCategoryId === i.Id}>
          <CollapsedLink to={`/dashboard/${i.Dashboards[0].CategoryId}`}>
            <div>
              <MenuIcon
                type={(categories[i.CategoryName] as IconType) ?? "bar-chart"}
                selected={selectedCategoryId === i.Id}
              />
            </div>
            <ListItemText selected={selectedCategoryId === i.Id}>
              {i.CategoryName}
            </ListItemText>
          </CollapsedLink>
          <ul>
            {i.Dashboards.map((j) => (
              <SubListItem
                key={j.Id}
                onClick={() => handleSubCategoryClick(j.CategoryId, j.Id)}
                selected={selectedDashboardId === j.Id}
              >
                <CollapsedLink
                  to={`/dashboard/${i.Dashboards[0].CategoryId}/sub-category/${j.Id}`}
                >
                  <SubListItemText selected={selectedDashboardId === j.Id}>
                    {j.Name.replaceAll("_", "")}
                  </SubListItemText>
                </CollapsedLink>
              </SubListItem>
            ))}
          </ul>
        </ListItem>
      ))}
    </ul>
  );
  const uncollapsedList = () => (
    <ul>
      {dashboards?.map((i) => (
        <ListItem key={i.Id} selected={selectedCategoryId === i.Id}>
          <UncollapsedLink to={`/dashboard/${i.Dashboards[0].CategoryId}`}>
            <CategoryListContainer>
              <div>
                <MenuIcon
                  type={(categories[i.CategoryName] as IconType) ?? "bar-chart"}
                  selected={selectedCategoryId === i.Id}
                />
              </div>

              <ListItemText selected={selectedCategoryId === i.Id}>
                {ellipsis(i.CategoryName)}
              </ListItemText>
              <MenuChevronIcon
                type="angle-right"
                open={selectedCategoryId === i.Id}
              />
            </CategoryListContainer>
          </UncollapsedLink>
          <Collapsible
            trigger={""}
            open={selectedCategoryId === i.Id}
            transitionTime={100}
          >
            <ul>
              {i.Dashboards.map((j) => (
                <SubListItem
                  key={j.Id}
                  onClick={() => handleSubCategoryClick(j.CategoryId, j.Id)}
                  selected={selectedDashboardId === j.Id}
                >
                  <SubListItemText selected={selectedDashboardId === j.Id}>
                    <UncollapsedLink
                      to={`/dashboard/${i.Dashboards[0].CategoryId}/sub-category/${j.Id}`}
                    >
                      <Tooltip title={j.Name.length > 22 ? j.Name : ""}>
                        <div
                          style={{
                            maxWidth: 200,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {j.Name}
                        </div>
                      </Tooltip>
                    </UncollapsedLink>
                  </SubListItemText>
                </SubListItem>
              ))}
            </ul>
          </Collapsible>
        </ListItem>
      ))}
    </ul>
  );

  return (
    <>
      <AppBar open={open}>
        <DrawerButton
          type={open ? "arrow-circle-left" : "menu"}
          onClick={() => setOpen(!open)}
        />
        <Spacer />
        <FullscreenButton
          type="fullscreen"
          onClick={() => {
            var dashboardFrame = document.getElementById("dashboard-frame");
            if (dashboardFrame?.requestFullscreen) {
              dashboardFrame.requestFullscreen();
            }
          }}
        />
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <ProfileButton {...bindTrigger(popupState)}>
                <Avatar src={userDetails?.Avatar}>{`${
                  userDetails?.FirstName?.[0] ?? "A"
                }${userDetails?.Lastname?.[0] ?? "A"}`}</Avatar>
              </ProfileButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem>
                  <Typography style={{ marginRight: 16 }}>{`${
                    userDetails?.FirstName ?? ""
                  } ${userDetails?.Lastname ?? ""}`}</Typography>
                </MenuItem>
                <MenuItem
                  style={{ minWidth: 180 }}
                  onClick={() => {
                    popupState.close();
                    navigate("/profile");
                  }}
                >
                  <ProfileIcon type="user" />
                  Meu Perfil
                </MenuItem>
                <MenuItem
                  style={{ minWidth: 180 }}
                  onClick={() => {
                    popupState.close();
                    navigate("/change-password");
                  }}
                >
                  <ProfileIcon type="lock" />
                  Mudar senha
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    popupState.close();
                    signout();
                  }}
                >
                  <ProfileIcon type="power-off" />
                  Sair
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </AppBar>
      <Drawer open={open}>
        <DrawerHeaderContainer>
          {open && getGroupTheme().theme.hideLogoWhenMenuUncollapsed ? (
            <div style={{ width: 40 }} />
          ) : null}
          {open && !getGroupTheme().theme.hideLogoWhenMenuUncollapsed ? (
            <img src={getGroupTheme().logo} width={40} alt="" />
          ) : null}
          {!open ? <img src={getGroupTheme().logo} width={40} alt="" /> : null}
          {open ? (
            <img
              src={getGroupTheme().group}
              style={{ marginLeft: 32 }}
              width="140"
              alt=""
            />
          ) : null}
        </DrawerHeaderContainer>
        {open ? <SectionText>-- DASHBOARDS</SectionText> : null}
        {open ? uncollapsedList() : collapsedList()}
        {open && isRavproUser(userRoles) ? (
          <ConfigMenuSection userRoles={userRoles} />
        ) : null}
      </Drawer>
      <Content open={open}>
        <Outlet />
      </Content>
    </>
  );
}

const isRavproUser = (userRoles: UserRole[]) =>
  !!userRoles.filter(
    (i) => i === UserRole.RavproAdmin || i === UserRole.RavproConsultant
  )?.[0];

const ellipsis = (name: string) => {
  if (name.length > 22) {
    return `${name.substring(0, 22)}...`;
  } else {
    return name;
  }
};

const MenuConfigContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const MenuConfigText = styled("span")`
  color: #54667a;
  flex: 1;
`;

const MenuConfigIcon = styled(Icon)`
  color: #54667a;
  padding: 16px 20px;
  font-size: 16px;
`;

type ConfigListItemProps = {
  icon: IconType;
  onClick: () => void;
};

const ConfigListItem: React.FC<ConfigListItemProps> = ({
  icon,
  children,
  onClick,
}) => {
  return (
    <MenuConfigContainer onClick={onClick}>
      <MenuConfigIcon type={icon} />
      <MenuConfigText>{children}</MenuConfigText>
    </MenuConfigContainer>
  );
};

const ConfigMenuSection = ({ userRoles }: { userRoles: UserRole[] }) => {
  const navigate = useNavigate();
  return (
    <div>
      <SectionText>-- CONFIGURAÇÕES</SectionText>
      <ConfigListItem icon="archive" onClick={() => navigate("/ravpro/groups")}>
        Grupos
      </ConfigListItem>
      <ConfigListItem
        icon="archive"
        onClick={() => navigate("/ravpro/dealerships")}
      >
        Empresas
      </ConfigListItem>
      <ConfigListItem
        icon="id-badge"
        onClick={() => navigate("/ravpro/consultants")}
      >
        Consultores
      </ConfigListItem>
      {userRoles.includes(UserRole.RavproAdmin) ? (
        <ConfigListItem
          icon="cup"
          onClick={() =>
            navigate("/ravpro/product-catalogs?manufacturer=RAVPRO")
          }
        >
          Catálogos
        </ConfigListItem>
      ) : null}
      <ConfigListItem
        icon="bag"
        onClick={() => navigate("/ravpro/products?manufacturer=RAVPRO")}
      >
        Produtos
      </ConfigListItem>
      <ConfigListItem icon="bag" onClick={() => navigate("/ravpro/services")}>
        Serviços
      </ConfigListItem>
      <ConfigListItem icon="bag" onClick={() => navigate("/ravpro/kits")}>
        Kits
      </ConfigListItem>
      <ConfigListItem
        icon="package"
        onClick={() => navigate("/ravpro/entries?premiumCalculationType=SALE")}
      >
        Lançamentos
      </ConfigListItem>
      <ConfigListItem
        icon="cup"
        onClick={() => navigate("/ravpro/premium-description")}
      >
        Desc. Prêmios
      </ConfigListItem>

      {/* <ConfigListItem
        open={open}
        icon="user"
        onClick={() => navigate("/ravpro/user-permissions")}
      >
        Permissões
      </ConfigListItem> */}
    </div>
  );
};
